@charset "UTF-8";

@font-face {
  font-family: "dashboard";
  src:url("../fonts/dashboard.eot");
  src:url("../fonts/dashboard.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dashboard.woff") format("woff"),
    url("../fonts/dashboard.ttf") format("truetype"),
    url("../fonts/dashboard.svg#dashboard") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dashboard" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dashboard" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\62";
}
.icon-form:before {
  content: "\61";
}
.icon-list:before {
  content: "\63";
}
.icon-presentation:before {
  content: "\65";
}
.icon-bill:before {
  content: "\66";
}
.icon-check:before {
  content: "\67";
}
.icon-list-1:before {
  content: "\68";
}
.icon-padnote:before {
  content: "\69";
}
.icon-pencil-case:before {
  content: "\6a";
}
.icon-user:before {
  content: "\6b";
}
.icon-bars:before {
  content: "\6c";
}
.icon-line-chart:before {
  content: "\70";
}
.icon-flask:before {
  content: "\6d";
}
.icon-grid:before {
  content: "\6e";
}
.icon-picture:before {
  content: "\6f";
}
.icon-website:before {
  content: "\71";
}
.icon-screen:before {
  content: "\72";
}
.icon-interface-windows:before {
  content: "\73";
}
.icon-clock:before {
  content: "\74";
}
.icon-rss-feed:before {
  content: "\75";
}
.icon-mail:before {
  content: "\64";
}
.icon-ios-email-outline:before {
  content: "\76";
}
.icon-paper-airplane:before {
  content: "\77";
}
.icon-ios-email:before {
  content: "\78";
}
.icon-page:before {
  content: "\41";
}
.icon-close:before {
  content: "\79";
}
.icon-search:before {
  content: "\7a";
}

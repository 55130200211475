/*
==========================================================
==========================================================

Bootstrapious Dashboard Template 1.2

https://bootstrapious.com/p/bootstrap-4-dashboard

==========================================================
==========================================================
*/
/*
* ==========================================================
*     GENERAL
* ==========================================================
*/
.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 0.9rem;
}

.text-xsmall {
  font-size: 0.8rem;
}

.text-gray {
  color: #868e96;
}

.text-uppercase {
  letter-spacing: 0.2em;
}

.lh-2 {
  line-height: 2;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
}

body {
  overflow-x: hidden;
}

i {
  display: inline-block;
}

span {
  display: inline-block;
}

.mt-30px {
  margin-top: 30px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

i[class*='icon-'] {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

table {
  font-size: 0.9em;
  color: #666;
}

.btn-xs {
  font-size: 0.7em !important;
  padding: 2px 5px !important;
  border-radius: 2px !important;
}

.badge {
  font-weight: 400;
  border-radius: 0;
}

.badge.badge-primary {
  background: #33b35a;
}

.page {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  width: calc(100% - 200px);
  background-color: #F4F7FA;
  min-height: 100vh;
  padding-bottom: 50px;
}

.page.active {
  width: calc(100% - 70px);
}

h2 {
  color: #555;
  font-weight: 400;
}

.breadcrumb-holder {
  background: #eceeef;
}

.breadcrumb-holder .breadcrumb {
  padding: 15px 0;
  font-size: 0.85em;
  font-weight: 300;
  color: #999;
  margin-bottom: 0;
}

.breadcrumb-holder .breadcrumb a:hover, .breadcrumb-holder .breadcrumb:focus {
  text-decoration: none;
}

.breadcrumb-holder .breadcrumb .container {
  padding: 0;
}

.card {
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  margin-bottom: 0;
}

.card:not([class*="text"]) p {
  color: #888;
}

.display {
  font-weight: 400 !important;
}

.section-padding {
  padding: 3rem 0;
}

.section-no-padding-bottom {
  padding-bottom: 0;
}

section header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .container-fluid {
    padding: 0 3rem;
  }
}

.list-group-item {
  border-right: 0;
  border-left: 0;
}

.list-group-item:first-child, .list-group-item:last-child {
  border-radius: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.tile-link {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 30;
}

/*
* ==========================================================
*     Main NAVBAR
* ==========================================================
*/
nav.navbar {
  background: #393836;
  padding: .8rem 0;
  /* Navbar Brand --------- */
  /*  Dropdowns ------------ */
  /* Toggle Button ---------- */
}

nav.navbar .side-navbar-wrapper {
  width: 100%;
  height: 100%;
}

nav.navbar .container-fluid {
  width: 100%;
}

nav.navbar .navbar-holder {
  width: 100%;
}

nav.navbar button {
  font-size: .8rem;
  color: #fff;
  position: relative;
}

nav.navbar .badge {
  position: absolute;
  top: 0;
  right: 0;
}

nav.navbar button.language span {
  margin-left: .2rem;
  vertical-align: middle;
}

nav.navbar button.logout i {
  margin-left: 5px;
}

nav.navbar small {
  font-size: 0.75rem;
  color: #999;
}

nav.navbar button.navbar-brand {
  margin-left: 10px;
}

nav.navbar .brand-text {
  text-transform: uppercase;
}

nav.navbar .brand-text strong {
  font-size: 1.5em;
}

nav.navbar .nav-menu {
  margin-bottom: 0;
}

nav.navbar .dropdown-toggle::after {
  border: none !important;
  content: '\f107';
  vertical-align: baseline;
  font-family: 'FontAwesome';
  margin-left: .3rem;
}

nav.navbar .dropdown-menu {
  right: 0;
  left: auto;
  float: left;
  margin-top: 16px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
  padding: 1rem;
}

nav.navbar .dropdown-menu .dropdown-item {
  color: #666;
  padding: 10px;
}

nav.navbar .dropdown-menu .dropdown-item:active {
  color: #fff;
}

nav.navbar .dropdown-menu .dropdown-item:active span,
nav.navbar .dropdown-menu .dropdown-item:active small {
  color: #fff;
}

nav.navbar .dropdown-menu .dropdown-item span {
  color: #777;
}

nav.navbar .dropdown-menu .dropdown-item i {
  margin-right: 5px;
}

nav.navbar .msg-body span,
nav.navbar .msg-body strong {
  display: block;
}

nav.navbar .msg-body span {
  font-size: 0.85em;
}

nav.navbar .msg-profile {
  margin-right: 10px;
  width: 50px;
}

nav.navbar .notification-content {
  font-size: .8rem;
  margin-right: .5rem;
}

nav.navbar button.menu-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #292929;
  color: #fff;
  text-align: center;
  padding: 0 !important;
}

nav.navbar button.menu-btn i[class*='icon-'] {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

nav.navbar button.menu-btn:hover {
  background: #33b35a;
}

/*  NAVBAR MEDIAQUERIES ---------------------------------- */
@media (max-width: 575px) {
  nav.navbar .dropdown {
    position: static;
  }
  nav.navbar .dropdown-menu {
    width: 90vw;
    right: 2.5vw;
    left: 2.5vw;
    margin: 0 auto;
  }
  nav.navbar .notification {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  nav.navbar .dropdown-menu strong {
    font-weight: 400;
  }
  nav.navbar .dropdown-menu a {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*     SIDE NAVBAR
* ==========================================================
*/
.side-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  width: 200px;
  text-align: left;
  background: #393836;
  color: #fff;
  z-index: 999;
  overflow-y: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /*  Side navbar small ------------------------ */
}

.side-navbar .scrolled-wrapper {
  width: 100%;
  height: 100%;
}

.side-navbar.hide {
  left: -70px;
}

.side-navbar button.brand-small {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0;
}

.side-navbar button.brand-small:hover, .side-navbar button.brand-small:focus {
  background: none;
}

.side-navbar button.brand-small strong {
  font-size: 1.5rem;
}

.side-navbar li {
  position: relative;
}

.side-navbar li a, .side-navbar li button {
  position: relative;
  font-size: .9rem;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  width: 100%;
  padding: 12px;
  background-color: transparent;
}

.side-navbar li a:focus, .side-navbar li a:hover, .side-navbar li a[aria-expanded="true"], .side-navbar li button:focus, .side-navbar li button:hover, .side-navbar li button[aria-expanded="true"] {
  background: #33b35a;
  color: #fff;
  text-decoration: none;
}

.side-navbar li a[data-toggle="collapse"]::before, .side-navbar li button[data-toggle="collapse"]::before {
  content: '\f104';
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'FontAwesome';
  position: absolute;
  top: 50%;
  right: 20px;
}

.side-navbar li a[aria-expanded="true"]::before, .side-navbar li button[aria-expanded="true"]::before {
  content: '\f107';
  color: #fff;
}

.side-navbar li.active > a {
  background: #33b35a;
}

.side-navbar li li.active > a {
  background: #2d9f50 !important;
}

.side-navbar li ul {
  background: #292929;
  position: relative;
  min-width: 100%;
  left: 0;
  padding-left: 0;
}

.side-navbar li ul a {
  padding-left: 20px;
  font-size: .85rem;
}

.side-navbar li ul.collapse a {
  border-left: 2px solid #33b35a;
}

.side-navbar li ul.collapse a:hover {
  background: #121212;
}

.side-navbar .sidenav-header {
  background: #292929;
  padding: 14px;
  margin-bottom: 10px;
}

.side-navbar .sidenav-header img {
  width: 50px;
  height: 50px;
}

.side-navbar .sidenav-header span {
  font-size: 0.8em;
  color: #666;
}

.side-navbar .sidenav-header h2 {
  margin: 10px 0 0;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.side-navbar .sidenav-heading {
  font-weight: 700;
  color: #adb5bd;
  font-size: .9rem;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.side-navbar i[class*='icon-'] {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.side-navbar span {
  display: inline-block;
}

.side-navbar i {
  font-size: inherit;
  margin-right: 10px;
}

.side-navbar .badge {
  display: inline-block;
  font-size: 0.6em;
  vertical-align: middle;
}

.side-navbar .brand-small {
  display: none;
}

.side-navbar.shrink {
  width: 70px;
  text-align: center;
}

.side-navbar.shrink .brand-small {
  display: block;
}

.side-navbar.shrink .sidenav-header-inner {
  display: none;
}

.side-navbar.shrink .sidenav-heading {
  margin-left: 0;
  font-size: .8rem;
}

.side-navbar.shrink ul li a, .side-navbar.shrink ul li button {
  font-size: .7rem;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center !important;
}

.side-navbar.shrink ul li a[data-toggle="collapse"]::before, .side-navbar.shrink ul li button[data-toggle="collapse"]::before {
  content: '\f107';
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  position: absolute;
  top: auto;
  right: 50%;
  bottom: 0;
  left: auto;
}

.side-navbar.shrink ul li a[data-toggle="collapse"][aria-expanded="true"]::before, .side-navbar.shrink ul li button[data-toggle="collapse"][aria-expanded="true"]::before {
  content: '\f106';
}

.side-navbar.shrink ul li ul a {
  padding-left: 10px;
  font-size: .7rem;
}

.side-navbar.shrink i {
  display: block;
  margin-right: 0;
  font-size: 1rem;
}

.side-navbar.shrink .arrow {
  float: none;
  -webkit-transform: none !important;
  transform: none !important;
}

.side-navbar.shrink .badge {
  display: none;
}

/* SIDE NAVBAR MEDIAQUERIES ------------------------------ */
@media (max-width: 1199px) {
  .page {
    width: 100%;
  }
  .page.active {
    width: 100%;
  }
  .page.active-sm {
    width: calc(100% - 70px);
  }
  .side-navbar {
    left: -70px;
    width: 70px;
    text-align: center;
  }
  .side-navbar.show-sm {
    left: 0 !important;
  }
  .side-navbar .brand-small {
    display: block;
  }
  .side-navbar .sidenav-header-inner {
    display: none;
  }
  .side-navbar .sidenav-heading {
    margin-left: 0;
    font-size: .8rem;
  }
  .side-navbar ul li a {
    font-size: .7rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  .side-navbar ul li a[data-toggle="collapse"]::before {
    content: '\f107';
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
  }
  .side-navbar ul li a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: '\f106';
  }
  .side-navbar ul li ul a {
    padding-left: 10px;
    font-size: .7rem;
  }
  .side-navbar i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
  }
  .side-navbar .arrow {
    float: none;
    -webkit-transform: none !important;
    transform: none !important;
  }
  .side-navbar .badge {
    display: none;
  }
}

/* Malihu Custom Scroll */
.mCSB_scrollTools {
  width: 4px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0 !important;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #33b35a;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
  background-color: #33b35a;
}

/*
* ==========================================================
*      DASHBOARD COUNTS
* ==========================================================
*/
.dashboard-counts {
  color: #333;
}

.dashboard-counts .count-title span {
  font-size: 0.8em;
  color: #aaa;
  display: block;
}

.dashboard-counts .count-title i {
  font-size: 1.5em;
  color: #33b35a;
}

.dashboard-counts .icon {
  margin-right: 10px;
}

.dashboard-counts strong {
  font-size: 0.9em;
  font-weight: 500;
  color: #555;
  display: block;
}

.dashboard-counts .count-number {
  font-size: 2.2em;
  font-weight: 300;
}

/* DASHBOARD COUNTS MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .dashboard-counts div[class*='col-'] {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .dashboard-counts strong {
    font-size: 1em;
  }
  .dashboard-counts span {
    font-size: 0.9em;
  }
  .dashboard-counts .count-number {
    font-size: 3em;
  }
}

/*
* ==========================================================
*     DASHBOARD HEADER
* ==========================================================
*/
.dashboard-header {
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.dashboard-header .check-lists {
  margin-top: 20px;
}

.dashboard-header .check-lists label {
  font-size: 0.75em;
}

.dashboard-header .pie-chart {
  position: relative;
  width: 300px;
  height: 300px;
  max-width: 100%;
  margin: 0 auto;
}

.dashboard-header p {
  font-size: 0.75rem;
  font-weight: 400;
  color: #888;
}

.dashboard-header li {
  margin-bottom: 10px;
}

/* DASHBOARD HEADER MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .dashboard-header {
    background: none;
    border: none;
    padding: 0;
  }
  .dashboard-header div[class*='col-'] {
    margin-bottom: 30px;
  }
  .dashboard-header div[class*='col-']:last-of-type {
    margin-bottom: 0;
  }
  .dashboard-header .card {
    background: #fff;
    padding: 30px;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
}

@media (min-width: 768px) {
  .dashboard-header p {
    font-size: 0.9em;
  }
  .dashboard-header h2 {
    font-size: 1.3rem;
  }
  .dashboard-header .check-lists label {
    font-size: 0.85em;
  }
}

@media (min-width: 992px) {
  .dashboard-header {
    margin-bottom: 30px;
  }
  .dashboard-header .card {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
  }
}

/*
* ==========================================================
*      STATISTICS
* ==========================================================
*/
.statistics {
  /* User Activity ------------- */
}

.statistics div[class*='col-'] .card {
  padding: 20px;
  margin-bottom: 0;
  height: 100%;
}

.statistics h2 {
  margin-bottom: 20px;
}

.statistics p {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
}

.statistics .income .icon {
  font-size: 3.5em;
}

.statistics .income .number {
  font-size: 2.5em;
  color: #555;
}

.statistics .income strong {
  font-size: 1.5em;
}

.statistics .data-usage canvas {
  width: 100%;
  max-width: 200px;
  border: none;
}

.statistics .data-usage strong {
  font-size: 1.5em;
  display: block;
}

.statistics .data-usage small {
  font-size: 0.85em;
  font-weight: 300;
  color: #aaa;
}

.statistics .data-usage span {
  font-size: 0.95em;
  color: #aaa;
  display: block;
}

.statistics .data-usage p {
  margin-top: 10px;
  margin-bottom: 0;
}

.statistics .user-activity .number {
  font-size: 2.5em;
  font-weight: 300;
  color: #555;
}

.statistics .user-activity .progress {
  border-radius: 0;
  background: #f5f5f5;
}

.statistics .user-activity .progress .progress-bar.bg-primary {
  background: #33b35a !important;
}

.statistics .user-activity .page-statistics {
  margin-top: 20px;
}

.statistics .user-activity .page-statistics strong {
  display: block;
  text-transform: uppercase;
  color: #33b35a;
  font-size: 2em;
}

/* STATISTICS  MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .statistics div[class*='col-'] .card {
    height: auto;
    margin-bottom: 30px;
  }
  .statistics div[class*='col-']:last-of-type .card {
    margin-bottom: 0;
  }
  .statistics .data-usage {
    text-align: center;
  }
  .statistics .data-usage div[class*='col-']:first-of-type {
    padding-bottom: 20px;
  }
  .statistics .data-usage .gmpc {
    padding-bottom: 20px !important;
  }
  p {
    font-size: 0.75em;
  }
}

@media (min-width: 768px) {
  .statistics h2 {
    font-size: 1.3rem;
  }
}

/*
* ==========================================================
*      UPDATES WIDGETS
* ==========================================================
*/
.updates .left-col {
  padding-right: 20px;
}

.updates .icon {
  margin-right: 10px;
}

.updates li {
  border-bottom: 1px solid #f3f3f3;
  padding: .75rem 1.25rem;
}

.updates .update-date {
  font-size: 1.3em;
  font-weight: 700;
  color: #bbb;
  display: block;
}

.updates span {
  font-size: 0.7em;
  font-weight: 300;
  color: #aaa;
  line-height: 0.5em;
  display: block;
}

.updates strong {
  font-weight: 400;
  color: #555;
  font-size: 0.9em;
}

.updates small {
  font-size: 0.75em;
}

.updates a {
  color: #555;
}

.updates a:hover, .updates a:focus {
  color: #555;
  text-decoration: none;
}

.updates h2 {
  margin-bottom: 0;
}

.updates p {
  font-size: 0.9em;
  color: #999;
  margin-bottom: 5px;
}

/* UPDATES MEDIAQUERIES ------------------------- */
@media (max-width: 991px) {
  .updates h2 {
    font-size: 1.05rem;
  }
  .updates strong {
    font-size: 1em;
    font-weight: 400;
  }
  .updates small {
    font-size: 0.85em;
  }
  .updates .full-date {
    font-size: 0.85em;
  }
}

/*
* ==========================================================
*      DAILY FEEDS AND ACTIVITES WIDGETS
* ==========================================================
*/
/*  Daily Feeds ------------------- */
.daily-feeds a.feed-profile {
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.daily-feeds a.feed-profile img {
  width: 100%;
  height: 100%;
}

.daily-feeds .badge {
  margin-right: 10px;
}

.daily-feeds strong {
  display: inline-block;
  margin-right: 5px;
}

.daily-feeds small {
  color: #999;
  display: block;
  margin-bottom: 5px;
}

.daily-feeds .message-card {
  padding: 10px;
  background: #fafafa;
  margin-left: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.daily-feeds .full-date {
  line-height: 0.7em;
  margin-bottom: 10px;
}

.daily-feeds .CTAs a {
  margin-right: 5px;
}

.daily-feeds .CTAs a i {
  margin-right: 5px;
}

.daily-feeds .CTAs a:last-of-type {
  margin-right: 0;
}

.daily-feeds a.btn {
  color: #fff;
}

/* Activities  ------------------- */
.activities li {
  padding: 0;
}

.activities .date-holder {
  padding-right: 0;
}

.activities .date-holder .date {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.activities .date-holder .date span {
  font-size: 0.8em;
}

.activities .date-holder .icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #f5f5f5;
  text-align: center;
  margin: 0;
  display: inline-block;
}

.activities .date-holder span {
  display: block;
  margin: 10px;
}

.activities .content {
  border-left: 1px solid #eee;
  border-bottom: 20px;
  padding: 10px 20px;
}

.activities .content strong {
  display: block;
  margin-right: 5px;
}

/* UPDATES WIDGET MEDIAQUERIES ------------------------- */
@media (min-width: 768px) {
  .updates strong {
    font-size: 1em;
  }
  .updates small {
    font-size: 0.8em;
  }
}

/*
* ==========================================================
*      PROGRESS CIRCLE
* ==========================================================
*/
.gmpc {
  padding: 0 !important;
  width: 100% !important;
  height: auto !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #33b35a !important;
  border-right: 1px solid #eee;
}

@media (max-width: 575px) {
  .gmpc {
    border-right: none;
    border-bottom: 1px solid #eee;
  }
}

/*
* ==========================================================
*       MAIN FOOTER
* ==========================================================
*/
footer.main-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #222;
  padding: 10px 0;
}

footer.main-footer p {
  font-size: 0.7em;
  color: #777;
  margin: 0;
}

/* MAIN FOOTER MEDIAQUERIES ------------------------- */
@media (max-width: 575px) {
  footer.main-footer div[class*="col-"] {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  footer.main-footer p {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*       UTILITIES
* ==========================================================
*/
.bg-cover {
  background-size: cover;
}

/*
* ==========================================================
*     POLAR CHART
* ==========================================================
*/
.polar-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/*
* ==========================================================
*     PIE CHART
* ==========================================================
*/
.pie-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/*
* ==========================================================
*     RADAR CHART
* ==========================================================
*/
.radar-chart-example .chart-container {
  width: 500px;
  max-width: 100%;
  margin: 20px auto;
}

/* CHARTS MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  section.charts h2 {
    font-size: 1rem;
  }
}

/*
* ==========================================================
*     FORMS ELEMENTS
* ==========================================================
*/
label {
  font-size: .9rem;
}

.form-control {
  height: calc(2.4rem + 2px);
  padding: 0.45rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  border-radius: 0;
}

.form-control::-moz-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control:-ms-input-placeholder {
  font-size: 0.75em;
  font-weight: 400;
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

textarea.form-control {
  height: auto;
}

select.form-control {
  font-weight: 300;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

select.form-control option {
  color: #999;
  font-weight: 300;
}

.custom-select {
  height: calc(2.4rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.custom-select:focus {
  border-color: #88dda2;
  outline: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.form-group {
  margin-bottom: 10px;
}

.form-group div[class*='col-'] {
  margin-bottom: 10px;
}

.input-group-text {
  color: #868e96;
  background: #fff;
  border-radius: 0;
}

.form-group-material {
  position: relative;
  margin-bottom: 30px;
}

input.input-material {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  color: #868e96;
  font-weight: 300;
}

input.input-material.is-valid {
  border-color: #28a745 !important;
}

input.input-material.is-invalid {
  border-color: #dc3545 !important;
}

input.input-material:focus {
  border-color: #33b35a;
  outline: none;
}

input.input-material ~ label {
  color: #aaa;
  position: absolute;
  top: 14px;
  left: 0;
  cursor: text;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 300;
}

input.input-material ~ label.active {
  font-size: 0.8rem;
  top: -10px;
  color: #33b35a;
}

input.input-material.is-invalid ~ label {
  color: #dc3545;
}

input.form-control-custom {
  visibility: hidden;
  width: 1px;
}

input.form-control-custom:checked + label::before {
  background: #33b35a !important;
}

input.form-control-custom:checked + label::after {
  opacity: 1 !important;
}

input.form-control-custom.radio-custom + label::before {
  border-radius: 50%;
}

input.form-control-custom.radio-custom + label::after {
  content: '';
  width: 6px;
  height: 6px;
  line-height: 6px;
  background: #fff;
  border-radius: 50%;
  top: 7px;
  left: 5px;
}

input.form-control-custom[disabled] + label {
  cursor: not-allowed;
}

input.form-control-custom[disabled] + label::before, input.form-control-custom[disabled] + label::after {
  border: 2px solid #aaa;
  cursor: not-allowed;
}

input.form-control-custom[checked] + label::before, input.form-control-custom[checked] + label::after {
  border: none;
}

input.form-control-custom + label {
  font-size: 0.85rem;
  margin-bottom: 0;
  margin-left: 0;
  color: #999;
  padding-left: 25px;
  padding-top: 2px;
  position: relative;
}

input.form-control-custom + label span {
  margin-left: 20px;
}

input.form-control-custom + label::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: #ddd;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 2px;
  left: 0;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  cursor: pointer;
}

input.form-control-custom + label::after {
  content: '\f00c';
  display: block;
  font-family: 'FontAwesome';
  font-size: 8px;
  color: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0;
  cursor: pointer;
}

button {
  cursor: pointer;
}

/*
* ==========================================================
*     FORMS GENERAL
* ==========================================================
*/
.forms .line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #eee;
  margin: 20px 0 30px;
}

.forms p {
  font-size: 0.8rem;
  color: #868e96;
}

.forms small {
  color: #868e96;
}

/* FORMS MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  .forms h2 {
    font-size: 1rem;
  }
  .forms input.form-control {
    border-radius: 0;
  }
  .forms input.form-control::-moz-placeholder {
    font-size: 0.85em;
  }
  .forms input.form-control::-webkit-input-placeholder {
    font-size: 0.85em;
  }
  .forms input.form-control:-ms-input-placeholder {
    font-size: 0.85em;
  }
  .forms p {
    font-size: 0.9em;
  }
}

/*
* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/
.login-page,
.register-page {
  width: 100%;
  background: #f5f5f5;
  min-height: auto;
  padding-bottom: 0;
}

.login-page .form-outer,
.register-page .form-outer {
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}

.login-page .copyrights,
.register-page .copyrights {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}

.login-page .copyrights p,
.register-page .copyrights p {
  font-size: 0.6em;
  font-weight: 300;
  margin-bottom: 10px;
}

.login-page .form-inner,
.register-page .form-inner {
  padding: 40px 30px;
  background: #fff;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.login-page .logo,
.register-page .logo {
  font-size: 2em;
  font-weight: 500;
}

.login-page .logo span,
.register-page .logo span {
  color: #ccc;
}

.login-page p,
.register-page p {
  font-size: 0.8em;
  font-weight: 300;
  color: #aaa;
  margin-bottom: 0;
}

.login-page form,
.register-page form {
  margin: 2rem auto 1rem;
  max-width: 300px;
}

.login-page a.forgot-pass,
.login-page a.signup,
.register-page a.forgot-pass,
.register-page a.signup {
  font-size: 0.65em;
  color: #66b0ff;
}

.login-page a.forgot-pass:hover, .login-page a.forgot-pass:focus,
.login-page a.signup:hover,
.login-page a.signup:focus,
.register-page a.forgot-pass:hover,
.register-page a.forgot-pass:focus,
.register-page a.signup:hover,
.register-page a.signup:focus {
  color: #66b0ff;
  text-decoration: none;
}

.login-page a.forgot-pass,
.register-page a.forgot-pass {
  display: block;
}

.login-page a.create-account,
.register-page a.create-account {
  border-radius: 50px;
  font-size: 0.75em;
  margin-top: 10px;
  color: #999;
  text-transform: uppercase;
}

.login-page .terms-conditions,
.register-page .terms-conditions {
  margin-top: 20px;
}

.login-page small,
.register-page small {
  font-size: 0.65em;
  color: #aaa;
  line-height: 0.9em;
}

/* LOGIN MEDIAQUERIES -------------------------- */
@media (min-width: 768px) {
  .login-page p,
  .register-page p {
    font-size: 0.9em;
  }
  .login-page form label.label-custom,
  .register-page form label.label-custom {
    font-size: 0.9em;
  }
  .login-page form small,
  .register-page form small {
    font-size: 0.8em;
  }
  .login-page .terms-conditions label,
  .register-page .terms-conditions label {
    font-size: 0.9em;
  }
  .login-page a.forgot-pass,
  .login-page a.signup,
  .register-page a.forgot-pass,
  .register-page a.signup {
    font-size: 0.8em;
  }
  .login-page small,
  .register-page small {
    font-size: 0.8em;
  }
  .login-page .copyrights p,
  .register-page .copyrights p {
    font-size: 0.85em;
    font-weight: 400;
  }
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  right: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  right: 0px;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================
   THEMING OF BOOTSTRAP COMPONENTS
   ========================================= */
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.45rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

.btn-primary {
  color: color-yiq(#33b35a);
  background-color: #33b35a;
  border-color: #33b35a;
}

.btn-primary:hover {
  color: color-yiq(#2b954b);
  background-color: #2b954b;
  border-color: #288b46;
}

.btn-primary:focus, .btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #33b35a;
  border-color: #33b35a;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: color-yiq(#288b46);
  background-color: #288b46;
  border-color: #258141;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-secondary {
  color: color-yiq(#868e96);
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: color-yiq(#727b84);
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus, .btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: color-yiq(#6c757d);
  background-color: #6c757d;
  border-color: #666e76;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-success {
  color: color-yiq(#28a745);
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: color-yiq(#218838);
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: color-yiq(#1e7e34);
  background-color: #1e7e34;
  border-color: #1c7430;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: color-yiq(#17a2b8);
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: color-yiq(#138496);
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: color-yiq(#117a8b);
  background-color: #117a8b;
  border-color: #10707f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: color-yiq(#ffc107);
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: color-yiq(#e0a800);
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: color-yiq(#d39e00);
  background-color: #d39e00;
  border-color: #c69500;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: color-yiq(#dc3545);
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: color-yiq(#c82333);
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: color-yiq(#bd2130);
  background-color: #bd2130;
  border-color: #b21f2d;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: color-yiq(#f8f9fa);
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: color-yiq(#e2e6ea);
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: color-yiq(#dae0e5);
  background-color: #dae0e5;
  border-color: #d3d9df;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: color-yiq(#343a40);
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: color-yiq(#23272b);
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: color-yiq(#1d2124);
  background-color: #1d2124;
  border-color: #171a1d;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-default {
  color: color-yiq(#ced4da);
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-default:hover {
  color: color-yiq(#b8c1ca);
  background-color: #b8c1ca;
  border-color: #b1bbc4;
}

.btn-default:focus, .btn-default.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-default.disabled, .btn-default:disabled {
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-default:not([disabled]):not(.disabled):active, .btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: color-yiq(#b1bbc4);
  background-color: #b1bbc4;
  border-color: #aab4bf;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.btn-outline-primary {
  color: #33b35a;
  background-color: transparent;
  background-image: none;
  border-color: #33b35a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #33b35a;
  border-color: #33b35a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #33b35a;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #33b35a;
  border-color: #33b35a;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(51, 179, 90, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

a {
  color: #33b35a;
  text-decoration: none;
}

a:focus, a:hover {
  color: #22773c;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 1.5rem;
}

h2,
.h2 {
  font-size: 1.3rem;
}

h3,
.h3 {
  font-size: 1.2rem;
}

h4,
.h4 {
  font-size: 1.1rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border-left: 5px solid #33b35a;
}

.blockquote-footer {
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #33b35a !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #288b46 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #33b35a;
  border-color: #33b35a;
}

.page-item.disabled .page-link {
  color: #868e96;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #33b35a;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus, .page-link:hover {
  color: #22773c;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
  outline: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. DROPDOWNS
*/
.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #33b35a;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
}

.dropdown-header {
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  color: #868e96;
}

/*
* 6. UTILITIES
*/
.bg-primary {
  background-color: #33b35a !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #288b46 !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #33b35a !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #33b35a !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #288b46 !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.badge-primary {
  color: color-yiq(#33b35a);
  background-color: #33b35a;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: color-yiq(#33b35a);
  text-decoration: none;
  background-color: #288b46;
}

.badge-secondary {
  color: color-yiq(#868e96);
  background-color: #868e96;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: color-yiq(#868e96);
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: color-yiq(#28a745);
  background-color: #28a745;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: color-yiq(#28a745);
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: color-yiq(#17a2b8);
  background-color: #17a2b8;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: color-yiq(#17a2b8);
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: color-yiq(#ffc107);
  background-color: #ffc107;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: color-yiq(#ffc107);
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: color-yiq(#dc3545);
  background-color: #dc3545;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: color-yiq(#dc3545);
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: color-yiq(#f8f9fa);
  background-color: #f8f9fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: color-yiq(#f8f9fa);
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: color-yiq(#343a40);
  background-color: #343a40;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: color-yiq(#343a40);
  text-decoration: none;
  background-color: #1d2124;
}

/*
* 7.CODE
*/
code {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #e83e8c;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #33b35a;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 0 solid #eee;
  border-radius: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: 0;
}

.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}
